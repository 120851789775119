import { Navigate } from "react-router-dom";
import DashboardLayout from "../components/layout/DashboardLayout";
import MainLayout from "../components/layout/MainLayout";
import BudgetScreen from "../pages/BudgetScreen";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import Register from "../pages/Register";
import { isLogged } from "../services/auth";
import EmailTemplate from '../components/email'

const PrivateRoute = ({ children }) => {
  if (isLogged()) return children;
  return <Navigate to="/login" />;
};

const routes = [
  {
    path: "app",
    element: <DashboardLayout />,
    children: [
      {
        path: "inicio",
        element: (
          <PrivateRoute>
            <BudgetScreen />
          </PrivateRoute>
        ),
      },
      {
        path: "clientes",
        element: (
          <PrivateRoute>
            <BudgetScreen />
          </PrivateRoute>
        ),
      },
      {
        path: "orcamentos",
        element: (
          <PrivateRoute>
            <BudgetScreen />
          </PrivateRoute>
        ),
      },
      {
        path: "email",
        element: (
          <EmailTemplate />
        ),
      },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "login", element: <Login /> },
      { path: "register", element: <Register /> },
      { path: "404", element: <NotFound /> },
      {
        path: "/",
        element: (
          <PrivateRoute>
            <Navigate to="/app/orcamentos" />
          </PrivateRoute>
        ),
      },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
