import React from "react";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { Search as SearchIcon } from "@material-ui/icons";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import DatePicker from "@material-ui/lab/DatePicker";
import { useBudgetFormContext } from "../../../context/BudgetFormContext";
import { addDays, differenceInDays } from "date-fns";

const FormHeader = () => {
  const { handleSearch, params, changeParams, loading } =
    useBudgetFormContext();

  const handleChange = (name, value) => {
    if (parseInt(value) < 0) return;

    changeParams({
      ...params,
      [name]: value,
    });
  };

  const handleChangeCheckIn = (date) => {
    const newDiff = differenceInDays(params.check_out, date);
    const oldDiff = differenceInDays(params.check_out, params.check_in);

    if (newDiff > 15 || newDiff < 0)
      return changeParams({
        ...params,
        check_in: date,
        check_out: addDays(date, oldDiff),
      });

    changeParams({
      ...params,
      check_in: date,
    });
  };

  const handleChangeCheckOut = (date) => {
    const diff = differenceInDays(date, params.check_in);

    // if (diff > 15 || diff < -15)
    //   return alert("Selecione um período de até 15 dias");

    if (diff < 0)
      return alert("Selecione um período maior que a data de CheckIn");

    changeParams({
      ...params,
      check_out: date,
    });
  };

  return (
    <Grid container={true} spacing={3}>
      <Grid item md={12} xs={12}>
        <FormControl sx={{ m: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Entrada"
              inputFormat="dd/MM/yyyy"
              value={params.check_in}
              onChange={handleChangeCheckIn}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Saída"
              inputFormat="dd/MM/yyyy"
              value={params.check_out}
              onChange={handleChangeCheckOut}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl sx={{ m: 1, width: 180 }}>
          <TextField
            label="Adultos"
            onChange={(e) => handleChange("adults_quantity", e.target.value)}
            value={params.adults_quantity}
            inputProps={{
              sx: { textAlign: "center" },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() =>
                      handleChange(
                        "adults_quantity",
                        params.adults_quantity - 1
                      )
                    }
                    edge="start"
                  >
                    -
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      handleChange(
                        "adults_quantity",
                        params.adults_quantity + 1
                      )
                    }
                    edge="end"
                  >
                    +
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: 180 }}>
          <TextField
            label="Crianças 0-3"
            onChange={(e) => handleChange("babies_quantity", e.target.value)}
            value={params.babies_quantity}
            inputProps={{
              sx: { textAlign: "center" },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() =>
                      handleChange(
                        "babies_quantity",
                        params.babies_quantity - 1
                      )
                    }
                    edge="start"
                  >
                    -
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      handleChange(
                        "babies_quantity",
                        params.babies_quantity + 1
                      )
                    }
                    edge="end"
                  >
                    +
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: 180 }}>
          <TextField
            label="Crianças 4-11"
            onChange={(e) => handleChange("children_quantity", e.target.value)}
            value={params.children_quantity}
            inputProps={{
              sx: { textAlign: "center" },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() =>
                      handleChange(
                        "children_quantity",
                        params.children_quantity - 1
                      )
                    }
                    edge="start"
                  >
                    -
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      handleChange(
                        "children_quantity",
                        params.children_quantity + 1
                      )
                    }
                    edge="end"
                  >
                    +
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 60 }}>
          <LoadingButton
            sx={{
              p: 2,
              color: "#fff",
              minHeight: 56,
            }}
            color="tertiary"
            variant="contained"
            onClick={handleSearch}
            loading={loading}
            loadingPosition="start"
          >
            {!loading && <SearchIcon />}
          </LoadingButton>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default FormHeader;
