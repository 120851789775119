import React, { useCallback, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import PropTypes from "prop-types";

function BudgetListToolbar({
  setIsForm,
  setActionButton,
  selectedCustomerIds,
  setTextosearch,
  setIsFilter,
  quantityFiltered,
  changeFilter,

}) {
  const getActionButton = useCallback(
    (item) => {
      if (item.length === 0) {
        setActionButton({ id: 1, text: "Adicionar Produto" }); // Adicionar um novo produto
      } else if (item.length === 1) {
        setActionButton({ id: 2, text: "Editar Produto" }); // Editar ou Excluir o produto selecionado
      } else if (item.length > 1) {
        setActionButton({ id: 3, text: "Excluir Produtos" }); // Exclui todos os produtos selecionados
      }
    },
    [setActionButton]
  );
  useEffect(() => {
    getActionButton(selectedCustomerIds);
  }, [selectedCustomerIds, getActionButton]);

  const clearFilter = () => {
      changeFilter('clerk', '');
      changeFilter('client', '');
      changeFilter('check_in', '');
      changeFilter('check_out', '');
      changeFilter('adults_quantity', 0);
      changeFilter('children_quantity', 0);
      changeFilter('babies_quantity', 0);
      changeFilter('price_up', null);
      changeFilter('price_down', null);
  }

  return (
    <Box sx={{ width: "100%", display: "flex" }}>
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "flex-start" }}
      >
        <Box sx={{ mt: 3, display: "flex" }}>
          <Button
            sx={{ maxHeight: 56, textTransform: "capitalize" }}
            variant="contained"
            color="secondary"
            onClick={() => setIsFilter(true)}
          >
            Filtros
            <Box
              sx={{
                backgroundColor: "#FBAF17",
                color: "#fff",
                fontWeight: 700,
                borderRadius: 100,
                height: 24,
                width: 24,
                textAlign: "center",
                ml: 1,
              }}
            >
              {quantityFiltered}
            </Box>
          </Button>

            {quantityFiltered > 0 && (
                <Button
                    sx={{ maxHeight: 56, textTransform: "capitalize", ml: 1 }}
                    variant="contained"
                    color="secondary"
                    onClick={clearFilter}
                >
                    Limpar Filtros
                </Button>
            )}

        </Box>
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ mt: 3, display: "flex" }}>
          <TextField
            fullWidth
            sx={{ ml: 2, width: 300 }}
            inputProps={{ style: { padding: "8px 14px" } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setTextosearch(e.target.value);
            }}
            placeholder="Pesquisar na tabela..."
            variant="outlined"
          />
          <Button
            sx={{
              ml: 2,
              color: "#fff",
              fontWeight: 700,
              textTransform: "capitalize",
            }}
            onClick={() => setIsForm(true)}
            color="tertiary"
            variant="contained"
          >
            Novo Orçamento
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

BudgetListToolbar.propTypes = {
  budgets: PropTypes.array.isRequired,
};

export default BudgetListToolbar;
