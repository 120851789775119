import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Box, Container } from "@material-ui/core";
import products_mocs from "../__mocks__/budgets";
import {
  BudgetForm,
  BudgetList,
  BudgetListToolbar,
} from "../components/budget";
import BudgetFormContextProvider from "../context/BudgetFormContext";
import useFilter from "../hooks/useFilter";

const BudgetScreen = () => {
  const _ = require("lodash");
  const [isForm, setIsForm] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [actionButton, setActionButton] = useState({
    id: 1,
    text: "Adicionar Produto",
  });
  const [textosearch, setTextosearch] = useState("");
  const [quantityFiltered, setQuantityFiltered] = useState(0);
  const { filter, changeFilter } = useFilter();

  var [budgets, setProducts] = useState(products_mocs);
  var [productsFiltered, setProductsFiltered] = useState(products_mocs);

  const searchProducts = useCallback(() => {
    let list = _.filter(budgets, (o) => {
      return _.lowerCase(o.name).includes(_.lowerCase(textosearch));
    });
    setProductsFiltered(list);
  }, [_, budgets, textosearch]);

  useEffect(() => {
    searchProducts();
  }, [searchProducts, textosearch]);

  return (
    <>
      <Helmet>
        <title>Orçamentos</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          height: "100%",
          py: 3,
        }}
      >
        {isForm ? (
          <Container maxWidth={false}>
            <BudgetFormContextProvider setIsForm={setIsForm}>
              <BudgetForm />
            </BudgetFormContextProvider>
          </Container>
        ) : (
          <Container maxWidth={false}>
            <BudgetListToolbar
              budgets={productsFiltered}
              setProducts={setProducts}
              setProductsFiltered={setProductsFiltered}
              isForm={isForm}
              setIsForm={setIsForm}
              isFilter={isFilter}
              setIsFilter={setIsFilter}
              quantityFiltered={quantityFiltered}
              actionButton={actionButton}
              setActionButton={setActionButton}
              selectedCustomerIds={selectedCustomerIds}
              setSelectedCustomerIds={setSelectedCustomerIds}
              textosearch={textosearch}
              setTextosearch={setTextosearch}
              changeFilter={changeFilter}
            />

            <Box sx={{ pt: 3 }}>
              <BudgetList
                budgets={productsFiltered}
                selectedCustomerIds={selectedCustomerIds}
                setSelectedCustomerIds={setSelectedCustomerIds}
                isFilter={isFilter}
                setIsFilter={setIsFilter}
                setQuantityFiltered={setQuantityFiltered}
                filter={filter}
                changeFilter={changeFilter}
              />
            </Box>
          </Container>
        )}
      </Box>
    </>
  );
};

export default BudgetScreen;
