import { useCallback, useMemo, useState } from "react";
import { getTaxes } from "../services/taxService";

const useFetchTaxes = () => {
  const [data, setData] = useState({
    dates: [],
    tax: [],
    prices: [],
    rules: [],
  });

  const fetchData = useCallback(async (data) => {
    try {
      const response = await getTaxes(data);
      setData(response);
      // setData({
      //   dates: [
      //     "08/09/2021",
      //     "09/09/2021",
      //     "10/09/2021",
      //     "11/09/2021",
      //     "12/09/2021",
      //   ],
      //   tax: [
      //     {
      //       acommodation_type: "rooms123",
      //       "08/09/2021": "BAR22",
      //       "09/09/2021": "BAR23",
      //       "10/09/2021": "BAR19",
      //       "11/09/2021": "BAR19",
      //     },
      //     {
      //       acommodation_type: "superiors",
      //       "08/09/2021": "BAR22",
      //       "09/09/2021": "BAR22",
      //       "10/09/2021": "BAR19",
      //       "11/09/2021": "BAR18",
      //     },
      //     {
      //       acommodation_type: "suites",
      //       "08/09/2021": "BAR22",
      //       "09/09/2021": "BAR23",
      //       "10/09/2021": "BAR20",
      //       "11/09/2021": "BAR20",
      //     },
      //   ],
      //   prices: [
      //     {
      //       acommodation_description: "SUITE MASTER",
      //       "08/09/2021": {
      //         daily_price: 1258,
      //         min_room_nights: 2,
      //         restriction_type: null,
      //       },
      //       "09/09/2021": {
      //         daily_price: 1258,
      //         min_room_nights: 2,
      //         restriction_type: null,
      //       },
      //       "10/09/2021": {
      //         daily_price: 1901,
      //         min_room_nights: 3,
      //         restriction_type: null,
      //       },
      //       "11/09/2021": {
      //         daily_price: 2027,
      //         min_room_nights: 3,
      //         restriction_type: null,
      //       },
      //     },
      //     {
      //       acommodation_description: "SUPERIOR",
      //       "08/09/2021": {
      //         daily_price: 1011,
      //         min_room_nights: 2,
      //         restriction_type: true,
      //       },
      //       "09/09/2021": {
      //         daily_price: 1049,
      //         min_room_nights: 2,
      //         restriction_type: null,
      //       },
      //       "10/09/2021": {
      //         daily_price: 1440,
      //         min_room_nights: 3,
      //         restriction_type: null,
      //       },
      //       "11/09/2021": {
      //         daily_price: 1494,
      //         min_room_nights: 3,
      //         restriction_type: null,
      //       },
      //     },
      //   ],
      // });
    } catch (e) {
      console.log('Error fetch data ', e)
      // setData({
      //   dates: [
      //     "16/11/2021",
      //     "17/11/2021",
      //     "18/11/2021",
      //     "19/11/2021",
      //     "20/11/2021",
      //     "21/11/2021",
      //     "22/11/2021",
      //     "23/11/2021",
      //     "24/11/2021",
      //     "25/11/2021",
      //     "26/11/2021",
      //     "27/11/2021",
      //     "28/11/2021",
      //     "29/11/2021",
      //     "30/11/2021",
      //     "01/12/2021",
      //     "02/12/2021",
      //     "03/12/2021",
      //     "04/12/2021",
      //     "05/12/2021",
      //     "06/12/2021",
      //     "07/12/2021",
      //     "08/12/2021",
      //     "09/12/2021",
      //     "10/12/2021",
      //     "11/12/2021",
      //     "12/12/2021",
      //     "13/12/2021",
      //     "14/12/2021",
      //     "15/12/2021",
      //     "16/12/2021",
      //     "17/12/2021",
      //     "18/12/2021",
      //     "19/12/2021",
      //     "20/12/2021",
      //     "21/12/2021",
      //     "22/12/2021",
      //     "23/12/2021",
      //     "24/12/2021",
      //     "25/12/2021",
      //     "26/12/2021",
      //     "27/12/2021",
      //     "28/12/2021",
      //     "29/12/2021",
      //     "30/12/2021",
      //   ],
      //   tax: {
      //     rooms123: {
      //       "16/11/2021": "BAR20",
      //       "17/11/2021": "BAR19",
      //       "18/11/2021": "BAR18",
      //       "19/11/2021": "BAR18",
      //       "20/11/2021": "BAR18",
      //       "21/11/2021": "BAR19",
      //       "22/11/2021": "BAR20",
      //       "23/11/2021": "BAR20",
      //       "24/11/2021": "BAR19",
      //       "25/11/2021": "BAR20",
      //       "26/11/2021": "BAR18",
      //       "27/11/2021": "BAR18",
      //       "28/11/2021": "BAR21",
      //       "29/11/2021": "BAR21",
      //       "30/11/2021": "BAR21",
      //       "01/12/2021": "BAR21",
      //       "02/12/2021": "BAR21",
      //       "03/12/2021": "BAR18",
      //       "04/12/2021": "BAR18",
      //       "05/12/2021": "BAR21",
      //       "06/12/2021": "BAR20",
      //       "07/12/2021": "BAR20",
      //       "08/12/2021": "BAR20",
      //       "09/12/2021": "BAR19",
      //       "10/12/2021": "BAR19",
      //       "11/12/2021": "BAR19",
      //       "12/12/2021": "BAR21",
      //       "13/12/2021": "BAR21",
      //       "14/12/2021": "BAR22",
      //       "15/12/2021": "BAR22",
      //       "16/12/2021": "BAR22",
      //       "17/12/2021": "BAR16",
      //       "18/12/2021": "BAR16",
      //       "19/12/2021": "BAR19",
      //       "20/12/2021": "BAR19",
      //       "21/12/2021": "BAR18",
      //       "22/12/2021": "BAR11",
      //       "23/12/2021": "BAR11",
      //       "24/12/2021": "BAR11",
      //       "25/12/2021": "BAR11",
      //       "26/12/2021": "BAR11",
      //       "27/12/2021": "BAR11",
      //       "28/12/2021": "BAR11",
      //       "29/12/2021": "BAR07",
      //     },
      //     superiors: {
      //       "16/11/2021": "BAR19",
      //       "17/11/2021": "BAR18",
      //       "18/11/2021": "BAR18",
      //       "19/11/2021": "BAR18",
      //       "20/11/2021": "BAR18",
      //       "21/11/2021": "BAR18",
      //       "22/11/2021": "BAR18",
      //       "23/11/2021": "BAR18",
      //       "24/11/2021": "BAR18",
      //       "25/11/2021": "BAR18",
      //       "26/11/2021": "BAR19",
      //       "27/11/2021": "BAR20",
      //       "28/11/2021": "BAR20",
      //       "29/11/2021": "BAR20",
      //       "30/11/2021": "BAR20",
      //       "01/12/2021": "BAR20",
      //       "02/12/2021": "BAR20",
      //       "03/12/2021": "BAR18",
      //       "04/12/2021": "BAR18",
      //       "05/12/2021": "BAR20",
      //       "06/12/2021": "BAR20",
      //       "07/12/2021": "BAR19",
      //       "08/12/2021": "BAR20",
      //       "09/12/2021": "BAR18",
      //       "10/12/2021": "BAR18",
      //       "11/12/2021": "BAR18",
      //       "12/12/2021": "BAR20",
      //       "13/12/2021": "BAR20",
      //       "14/12/2021": "BAR22",
      //       "15/12/2021": "BAR22",
      //       "16/12/2021": "BAR22",
      //       "17/12/2021": "BAR16",
      //       "18/12/2021": "BAR16",
      //       "19/12/2021": "BAR19",
      //       "20/12/2021": "BAR19",
      //       "21/12/2021": "BAR17",
      //       "22/12/2021": "BAR11",
      //       "23/12/2021": "BAR10",
      //       "24/12/2021": "BAR10",
      //       "25/12/2021": "BAR10",
      //       "26/12/2021": "BAR10",
      //       "27/12/2021": "BAR12",
      //       "28/12/2021": "BAR07",
      //       "29/12/2021": "BAR06",
      //     },
      //     suites: {
      //       "16/11/2021": "BAR20",
      //       "17/11/2021": "BAR20",
      //       "18/11/2021": "BAR19",
      //       "19/11/2021": "BAR18",
      //       "20/11/2021": "BAR18",
      //       "21/11/2021": "BAR19",
      //       "22/11/2021": "BAR20",
      //       "23/11/2021": "BAR20",
      //       "24/11/2021": "BAR19",
      //       "25/11/2021": "BAR20",
      //       "26/11/2021": "BAR19",
      //       "27/11/2021": "BAR19",
      //       "28/11/2021": "BAR22",
      //       "29/11/2021": "BAR22",
      //       "30/11/2021": "BAR22",
      //       "01/12/2021": "BAR22",
      //       "02/12/2021": "BAR22",
      //       "03/12/2021": "BAR19",
      //       "04/12/2021": "BAR19",
      //       "05/12/2021": "BAR22",
      //       "06/12/2021": "BAR21",
      //       "07/12/2021": "BAR21",
      //       "08/12/2021": "BAR20",
      //       "09/12/2021": "BAR21",
      //       "10/12/2021": "BAR19",
      //       "11/12/2021": "BAR19",
      //       "12/12/2021": "BAR22",
      //       "13/12/2021": "BAR22",
      //       "14/12/2021": "BAR22",
      //       "15/12/2021": "BAR22",
      //       "16/12/2021": "BAR22",
      //       "17/12/2021": "BAR16",
      //       "18/12/2021": "BAR16",
      //       "19/12/2021": "BAR19",
      //       "20/12/2021": "BAR19",
      //       "21/12/2021": "BAR18",
      //       "22/12/2021": "BAR12",
      //       "23/12/2021": "BAR12",
      //       "24/12/2021": "BAR12",
      //       "25/12/2021": "BAR12",
      //       "26/12/2021": "BAR12",
      //       "27/12/2021": "BAR12",
      //       "28/12/2021": "BAR08",
      //       "29/12/2021": "BAR07",
      //     },
      //   },
      //   prices: {
      //     "SUITE LUXO": {
      //       "16/11/2021": {
      //         price: 1788,
      //         minRoomNight: null,
      //         restriction: null,
      //       },
      //       "17/11/2021": {
      //         price: 1905,
      //         minRoomNight: 3,
      //         restriction: null,
      //       },
      //       "18/11/2021": {
      //         price: 2023,
      //         minRoomNight: 4,
      //         restriction: null,
      //       },
      //       "19/11/2021": {
      //         price: 2023,
      //         minRoomNight: 4,
      //         restriction: null,
      //       },
      //       "20/11/2021": {
      //         price: 2023,
      //         minRoomNight: 4,
      //         restriction: null,
      //       },
      //       "21/11/2021": {
      //         price: 2023,
      //         minRoomNight: 3,
      //         restriction: null,
      //       },
      //       "22/11/2021": {
      //         price: 1905,
      //         minRoomNight: 3,
      //         restriction: null,
      //       },
      //       "23/11/2021": {
      //         price: 1905,
      //         minRoomNight: 3,
      //         restriction: null,
      //       },
      //       "24/11/2021": {
      //         price: 1905,
      //         minRoomNight: 3,
      //         restriction: null,
      //       },
      //       "25/11/2021": {
      //         price: 1905,
      //         minRoomNight: 3,
      //         restriction: null,
      //       },
      //       "26/11/2021": {
      //         price: 2101,
      //         minRoomNight: 4,
      //         restriction: null,
      //       },
      //       "27/11/2021": {
      //         price: 2023,
      //         minRoomNight: 4,
      //         restriction: null,
      //       },
      //       "28/11/2021": {
      //         price: 1710,
      //         minRoomNight: null,
      //         restriction: null,
      //       },
      //       "29/11/2021": {
      //         price: 1710,
      //         minRoomNight: null,
      //         restriction: null,
      //       },
      //       "30/11/2021": {
      //         price: 1710,
      //         minRoomNight: null,
      //         restriction: null,
      //       },
      //       "01/12/2021": {
      //         price: 1710,
      //         minRoomNight: null,
      //         restriction: null,
      //       },
      //       "02/12/2021": {
      //         price: 1710,
      //         minRoomNight: null,
      //         restriction: null,
      //       },
      //       "03/12/2021": {
      //         price: 2023,
      //         minRoomNight: 4,
      //         restriction: null,
      //       },
      //       "04/12/2021": {
      //         price: 2101,
      //         minRoomNight: 4,
      //         restriction: null,
      //       },
      //       "05/12/2021": {
      //         price: 1710,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "06/12/2021": {
      //         price: 1710,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "07/12/2021": {
      //         price: 1710,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "08/12/2021": {
      //         price: 1788,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "09/12/2021": {
      //         price: 1905,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "10/12/2021": {
      //         price: 2101,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "11/12/2021": {
      //         price: 2101,
      //         minRoomNight: 4,
      //         restriction: null,
      //       },
      //       "12/12/2021": {
      //         price: 1632,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "13/12/2021": {
      //         price: 1632,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "14/12/2021": {
      //         price: 1632,
      //         minRoomNight: 5,
      //         restriction: null,
      //       },
      //       "15/12/2021": {
      //         price: 1632,
      //         minRoomNight: 5,
      //         restriction: null,
      //       },
      //       "16/12/2021": {
      //         price: 1632,
      //         minRoomNight: 5,
      //         restriction: null,
      //       },
      //       "17/12/2021": {
      //         price: 2140,
      //         minRoomNight: 5,
      //         restriction: null,
      //       },
      //       "18/12/2021": {
      //         price: 2140,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "19/12/2021": {
      //         price: 1905,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "20/12/2021": {
      //         price: 1905,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "21/12/2021": {
      //         price: 2023,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "22/12/2021": {
      //         price: 2882,
      //         minRoomNight: 5,
      //         restriction: null,
      //       },
      //       "23/12/2021": {
      //         price: 3078,
      //         minRoomNight: 5,
      //         restriction: null,
      //       },
      //       "24/12/2021": {
      //         price: 3078,
      //         minRoomNight: 5,
      //         restriction: null,
      //       },
      //       "25/12/2021": {
      //         price: 1417,
      //         minRoomNight: 4,
      //         restriction: null,
      //       },
      //       "26/12/2021": {
      //         price: 3078,
      //         minRoomNight: null,
      //         restriction: null,
      //       },
      //       "27/12/2021": {
      //         price: 3078,
      //         minRoomNight: null,
      //         restriction: null,
      //       },
      //       "28/12/2021": {
      //         price: 3664,
      //         minRoomNight: 5,
      //         restriction: null,
      //       },
      //       "29/12/2021": {
      //         price: 3859,
      //         minRoomNight: 7,
      //         restriction: null,
      //       },
      //     },
      //     "1 DORMITORIO": {
      //       "16/11/2021": {
      //         price: 1542,
      //         minRoomNight: null,
      //         restriction: null,
      //       },
      //       "17/11/2021": {
      //         price: 1634,
      //         minRoomNight: 3,
      //         restriction: null,
      //       },
      //       "18/11/2021": {
      //         price: 1696,
      //         minRoomNight: 4,
      //         restriction: null,
      //       },
      //       "19/11/2021": {
      //         price: 1696,
      //         minRoomNight: 4,
      //         restriction: null,
      //       },
      //       "20/11/2021": {
      //         price: 1696,
      //         minRoomNight: 4,
      //         restriction: null,
      //       },
      //       "21/11/2021": {
      //         price: 1696,
      //         minRoomNight: 3,
      //         restriction: null,
      //       },
      //       "22/11/2021": {
      //         price: 1634,
      //         minRoomNight: 3,
      //         restriction: null,
      //       },
      //       "23/11/2021": {
      //         price: 1634,
      //         minRoomNight: 3,
      //         restriction: null,
      //       },
      //       "24/11/2021": {
      //         price: 1634,
      //         minRoomNight: 3,
      //         restriction: null,
      //       },
      //       "25/11/2021": {
      //         price: 1634,
      //         minRoomNight: 3,
      //         restriction: null,
      //       },
      //       "26/11/2021": {
      //         price: 1727,
      //         minRoomNight: 4,
      //         restriction: null,
      //       },
      //       "27/11/2021": {
      //         price: 1696,
      //         minRoomNight: 4,
      //         restriction: null,
      //       },
      //       "28/11/2021": {
      //         price: 1387,
      //         minRoomNight: null,
      //         restriction: null,
      //       },
      //       "29/11/2021": {
      //         price: 1387,
      //         minRoomNight: null,
      //         restriction: null,
      //       },
      //       "30/11/2021": {
      //         price: 1387,
      //         minRoomNight: null,
      //         restriction: null,
      //       },
      //       "01/12/2021": {
      //         price: 1387,
      //         minRoomNight: null,
      //         restriction: null,
      //       },
      //       "02/12/2021": {
      //         price: 1449,
      //         minRoomNight: null,
      //         restriction: null,
      //       },
      //       "03/12/2021": {
      //         price: 1696,
      //         minRoomNight: 4,
      //         restriction: null,
      //       },
      //       "04/12/2021": {
      //         price: 1727,
      //         minRoomNight: 4,
      //         restriction: null,
      //       },
      //       "05/12/2021": {
      //         price: 1449,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "06/12/2021": {
      //         price: 1449,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "07/12/2021": {
      //         price: 1449,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "08/12/2021": {
      //         price: 1542,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "09/12/2021": {
      //         price: 1634,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "10/12/2021": {
      //         price: 1727,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "11/12/2021": {
      //         price: 1696,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "12/12/2021": {
      //         price: 1387,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "13/12/2021": {
      //         price: 1387,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "14/12/2021": {
      //         price: 1325,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "15/12/2021": {
      //         price: 1325,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "16/12/2021": {
      //         price: 1325,
      //         minRoomNight: 5,
      //         restriction: null,
      //       },
      //       "17/12/2021": {
      //         price: 1727,
      //         minRoomNight: 5,
      //         restriction: null,
      //       },
      //       "18/12/2021": {
      //         price: 1727,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "19/12/2021": {
      //         price: 1542,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "20/12/2021": {
      //         price: 1634,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "21/12/2021": {
      //         price: 1634,
      //         minRoomNight: 2,
      //         restriction: null,
      //       },
      //       "22/12/2021": {
      //         price: 2316,
      //         minRoomNight: 4,
      //         restriction: null,
      //       },
      //       "23/12/2021": {
      //         price: null,
      //         minRoomNight: 5,
      //         restriction: null,
      //       },
      //       "24/12/2021": {
      //         price: null,
      //         minRoomNight: 4,
      //         restriction: null,
      //       },
      //       "25/12/2021": {
      //         price: 1154,
      //         minRoomNight: 4,
      //         restriction: null,
      //       },
      //       "26/12/2021": {
      //         price: 2781,
      //         minRoomNight: null,
      //         restriction: null,
      //       },
      //       "27/12/2021": {
      //         price: 2781,
      //         minRoomNight: null,
      //         restriction: null,
      //       },
      //       "28/12/2021": {
      //         price: 2316,
      //         minRoomNight: 7,
      //         restriction: null,
      //       },
      //       "29/12/2021": {
      //         price: 3091,
      //         minRoomNight: 7,
      //         restriction: null,
      //       },
      //     },
      //   },
      //   rules: [
      //     {
      //       id_hotel: 1,
      //       accomodation_date: "09/08/2021",
      //       rule_id: 9,
      //       rule_desc: "5x4 1ªNOITE FREE",
      //       rule_type_id: 3,
      //       rule_type_desc: "",
      //       number_night_free: 1,
      //       flag_night_free: "PRI",
      //       perc_discount: "",
      //       value_discount: "",
      //     },
      //     {
      //       id_hotel: 1,
      //       accomodation_date: "09/09/2021",
      //       rule_id: 9,
      //       rule_desc: "5x4 1ªNOITE FREE",
      //       rule_type_id: 3,
      //       rule_type_desc: "",
      //       number_night_free: 1,
      //       flag_night_free: "PRI",
      //       perc_discount: "",
      //       value_discount: "",
      //     },
      //     {
      //       id_hotel: 1,
      //       accomodation_date: "09/10/2021",
      //       rule_id: 9,
      //       rule_desc: "5x4 1ªNOITE FREE",
      //       rule_type_id: 3,
      //       rule_type_desc: "",
      //       number_night_free: 1,
      //       flag_night_free: "PRI",
      //       perc_discount: "",
      //       value_discount: "",
      //     },
      //     {
      //       id_hotel: 1,
      //       accomodation_date: "09/11/2021",
      //       rule_id: 9,
      //       rule_desc: "5x4 1ªNOITE FREE",
      //       rule_type_id: 3,
      //       rule_type_desc: "",
      //       number_night_free: 1,
      //       flag_night_free: "PRI",
      //       perc_discount: "",
      //       value_discount: "",
      //     },
      //     {
      //       id_hotel: 1,
      //       accomodation_date: "09/12/2021",
      //       rule_id: 9,
      //       rule_desc: "5x4 1ªNOITE FREE",
      //       rule_type_id: 3,
      //       rule_type_desc: "",
      //       number_night_free: 1,
      //       flag_night_free: "PRI",
      //       perc_discount: "",
      //       value_discount: "",
      //     },
      //     {
      //       id_hotel: 1,
      //       accomodation_date: "09/20/2021",
      //       rule_id: 13,
      //       rule_desc: "5x4 1ªNOITE FREE",
      //       rule_type_id: 3,
      //       rule_type_desc: "",
      //       number_night_free: 1,
      //       flag_night_free: "PRI",
      //       perc_discount: "",
      //       value_discount: "",
      //     },
      //     {
      //       id_hotel: 1,
      //       accomodation_date: "09/21/2021",
      //       rule_id: 13,
      //       rule_desc: "5x4 1ªNOITE FREE",
      //       rule_type_id: 3,
      //       rule_type_desc: "",
      //       number_night_free: 1,
      //       flag_night_free: "PRI",
      //       perc_discount: "",
      //       value_discount: "",
      //     },
      //     {
      //       id_hotel: 1,
      //       accomodation_date: "09/22/2021",
      //       rule_id: 13,
      //       rule_desc: "5x4 1ªNOITE FREE",
      //       rule_type_id: 3,
      //       rule_type_desc: "",
      //       number_night_free: 1,
      //       flag_night_free: "PRI",
      //       perc_discount: "",
      //       value_discount: "",
      //     },
      //     {
      //       id_hotel: 1,
      //       accomodation_date: "09/23/2021",
      //       rule_id: 13,
      //       rule_desc: "5x4 1ªNOITE FREE",
      //       rule_type_id: 3,
      //       rule_type_desc: "",
      //       number_night_free: 1,
      //       flag_night_free: "PRI",
      //       perc_discount: "",
      //       value_discount: "",
      //     },
      //     {
      //       id_hotel: 1,
      //       accomodation_date: "09/24/2021",
      //       rule_id: 13,
      //       rule_desc: "5x4 1ªNOITE FREE",
      //       rule_type_id: 3,
      //       rule_type_desc: "",
      //       number_night_free: 1,
      //       flag_night_free: "PRI",
      //       perc_discount: "",
      //       value_discount: "",
      //     },
      //     {
      //       id_hotel: 1,
      //       accomodation_date: "09/25/2021",
      //       rule_id: 13,
      //       rule_desc: "5x4 1ªNOITE FREE",
      //       rule_type_id: 3,
      //       rule_type_desc: "",
      //       number_night_free: 1,
      //       flag_night_free: "PRI",
      //       perc_discount: "",
      //       value_discount: "",
      //     },
      //     {
      //       id_hotel: 1,
      //       accomodation_date: "09/26/2021",
      //       rule_id: 15,
      //       rule_desc: "4RN -10%",
      //       rule_type_id: 4,
      //       rule_type_desc: "",
      //       number_night_free: "",
      //       flag_night_free: "",
      //       perc_discount: "",
      //       value_discount: 10,
      //     },
      //     {
      //       id_hotel: 1,
      //       accomodation_date: "09/27/2021",
      //       rule_id: 15,
      //       rule_desc: "4RN -10%",
      //       rule_type_id: 4,
      //       rule_type_desc: "",
      //       number_night_free: "",
      //       flag_night_free: "",
      //       perc_discount: "",
      //       value_discount: 10,
      //     },
      //     {
      //       id_hotel: 1,
      //       accomodation_date: "09/28/2021",
      //       rule_id: 15,
      //       rule_desc: "4RN -10%",
      //       rule_type_id: 4,
      //       rule_type_desc: "",
      //       number_night_free: "",
      //       flag_night_free: "",
      //       perc_discount: "",
      //       value_discount: 10,
      //     },
      //     {
      //       id_hotel: 1,
      //       accomodation_date: "09/29/2021",
      //       rule_id: 15,
      //       rule_desc: "4RN -10%",
      //       rule_type_id: 4,
      //       rule_type_desc: "",
      //       number_night_free: "",
      //       flag_night_free: "",
      //       perc_discount: "",
      //       value_discount: 10,
      //     },
      //   ],
      // });
    }
  }, []);

  return useMemo(
    () => ({
      data,
      fetchData,
    }),
    [data, fetchData]
  );
};

export default useFetchTaxes;
