import {useCallback, useEffect, useMemo, useState} from "react";
import {uploadFile} from "../services/emailService";

const useFetchTaxes = () => {
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [copyEmail, setCopyEmail] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [body, setBody] = useState("");
  const [attachments, setAttachments] = useState('');
  const [clientNameError, setClientNameError] = useState(true);
  const [clientEmailError, setClientEmailError] = useState(true);
  const [clientPhoneError, setClientPhoneError] = useState(true);

  useEffect(() => {
      if(!clientName) setClientNameError(true);
      if(!clientEmail) setClientEmailError(true);
      if(!clientPhone) setClientPhoneError(true);
  }, [clientName, clientEmail, clientPhone])

  const changeMail = useCallback(async(name, value) => {
    switch (name) {
      case "clientName":
        return setClientName(value);
      case "clientEmail":
        return setClientEmail(value);
        case "copyEmail":
        return setCopyEmail(value);
      case "clientPhone":
        return setClientPhone(value);
      case "clientNameError":
        return setClientNameError(value);
      case "clientEmailError":
        return setClientEmailError(value);
      case "clientPhoneError":
        return setClientPhoneError(value);
      case "body":
        return setBody(value);
      case "attachments":
        const dataForm = new FormData();
        Object.keys(value).map((key) =>
          dataForm.append('files', value[key])
        )
        const fileList = await uploadFile(dataForm);
        return setAttachments(fileList);
      default:
        return null;
    }
  }, []);

  return useMemo(
    () => ({
      mail: {
        clientName,
        clientEmail,
        copyEmail,
        clientPhone,
        clientNameError,
        clientEmailError,
        clientPhoneError,
        body,
        attachments
      },
      changeMail,
    }),
    [
        clientName,
        clientEmail,
        clientPhone,
        body,
        changeMail,
        copyEmail,
        attachments,
        clientNameError,
        clientEmailError,
        clientPhoneError,
    ]
  );
};

export default useFetchTaxes;
