import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/layout/GlobalStyles';
import theme from './components/theme';
import routes from './store/routes';
import {BrowserRouter as Router} from 'react-router-dom';

const AppRoute = () => {
  const routing = useRoutes(routes);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
    </ThemeProvider>
  );
}
const App = () => {
  return (
    <Router>
      <AppRoute />
    </Router>
  );
};


export default App;
