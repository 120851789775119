import { useBudgetFormContext } from "../../context/BudgetFormContext";

const Steps = ({ children }) => {
  const { step } = useBudgetFormContext();

  if (!Array.isArray(children)) return null;

  return children[step];
};

export default Steps;
