import { colors } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import shadows from "./shadows";
import typography from "./typography";

const theme = createTheme({
  palette: {
    background: {
      default: "#F4F6F8",
      paper: colors.common.white,
    },
    primary: {
      contrastText: "#ffffff",
      main: "#034EA2",
    },
    secondary: {
      main: "#fff",
    },
    tertiary: {
      main: "#FBAF17",
    },
    fourth: {
      main: "#E9ECEF",
    },
    fifth: {
      main: "#2F9E44",
    },
    text: {
      primary: "#172b4d",
      secondary: "#6b778c",
      tertiary: "#FFFFFF",
      fourth: "#868E96",
      fifth: "FFFFFF"
    },
  },
  shadows,
  typography,
});

export default theme;
