import React, { useEffect, useState, useRef } from 'react';
import { Editor as EditorTiny } from "@tinymce/tinymce-react";
import { getEmail, saveEmail } from '../../services/emailService';
import { Button } from "@material-ui/core";

const EmailTemplate = () => {
    const [email, setEmail] = useState();
    const editorRef = useRef(null);
    useEffect(() => {
        const fetch = async () => {
            const email = await getEmail({fileName: 'email-template.html'})
            setEmail(email)  
        }
        fetch();      
    }, [])

    return (
        <div style={{margin: '30px'}}>
            <EditorTiny
                initialValue={email}
                apiKey='lk7fq643xfhmzfl6rg6yzb78cqmsrqbk31b8rihiefcvukuy'
                onInit={(e, editor) => editorRef.current = editor}
                init={{
                    height: 650,
                    menubar: false,
                    statusbar: false,
                    plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                    content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
                // onEditorChange={(newValue, editor) =>
                //     setDa(editor.getContent())
                // }
            />
            <Button
                sx={{ textTransform: "capitalize", color: "#fff", mt: 1, float: 'right' }}
                color="tertiary"
                variant="contained"
                onClick={async () =>
                    await saveEmail({htmlEmail: editorRef.current.getContent()})
                }
            >
            Salvar mudanças no e-mail
            </Button>
        </div>
    );
};

export default EmailTemplate;
