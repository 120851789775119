import React from "react";
import {
    FormControl,
    IconButton,
    InputAdornment,
    Modal,
    TextField,
    Card,
    CardHeader,
    CardContent,
    Divider, Button,
} from "@material-ui/core";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import DatePicker from "@material-ui/lab/DatePicker";

const ModalFilter = ({open, onClose, setIsFilter, changeFilter, params}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{width: '30%', margin: 'auto', overflow: 'scroll'}}
        >
            <Card>
                <CardHeader title={'Filtros'} />
                <Divider />
                <CardContent style={{display: 'flex', flexDirection: 'column'}}>

                    <FormControl sx={{ m: 1, color: '#ffffff' }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Entrada"
                                inputFormat="dd/MM/yyyy"
                                value={params.check_in}
                                onChange={(date) => changeFilter('check_in', date)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl sx={{ m: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Saída"
                                inputFormat="dd/MM/yyyy"
                                value={params.check_out}
                                onChange={(date) => changeFilter('check_out', date)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                        <TextField
                            label="Cliente"
                            onChange={(e) => changeFilter("client", e.target.value)}
                            value={params.client}
                            inputProps={{
                                sx: { textAlign: "center" },
                            }}
                        />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                        <TextField
                            label="Operador"
                            onChange={(e) => changeFilter("clerk", e.target.value)}
                            value={params.clerk}
                            inputProps={{
                                sx: { textAlign: "center" },
                            }}
                        />

                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                        <TextField
                            label="Adultos"
                            onChange={(e) => changeFilter("adults_quantity", e.target.value)}
                            value={params.adults_quantity}
                            inputProps={{
                                sx: { textAlign: "center" },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton
                                            onClick={() =>
                                                changeFilter(
                                                    "adults_quantity",
                                                    params.adults_quantity - 1
                                                )
                                            }
                                            edge="start"
                                        >
                                            -
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                changeFilter(
                                                    "adults_quantity",
                                                    params.adults_quantity + 1
                                                )
                                            }
                                            edge="end"
                                        >
                                            +
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                        <TextField
                            label="Crianças 4-11 anos"
                            onChange={(e) => changeFilter("children_quantity", e.target.value)}
                            value={params.children_quantity}
                            inputProps={{
                                sx: { textAlign: "center" },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton
                                            onClick={() =>
                                                changeFilter(
                                                    "children_quantity",
                                                    params.children_quantity - 1
                                                )
                                            }
                                            edge="start"
                                        >
                                            -
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                changeFilter(
                                                    "children_quantity",
                                                    params.children_quantity + 1
                                                )
                                            }
                                            edge="end"
                                        >
                                            +
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                        <TextField
                            label="Crianças 0-3 anos"
                            onChange={(e) => changeFilter("babies_quantity", e.target.value)}
                            value={params.babies_quantity}
                            inputProps={{
                                sx: { textAlign: "center" },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton
                                            onClick={() =>
                                                changeFilter(
                                                    "babies_quantity",
                                                    params.babies_quantity - 1
                                                )
                                            }
                                            edge="start"
                                        >
                                            -
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                changeFilter(
                                                    "babies_quantity",
                                                    params.babies_quantity + 1
                                                )
                                            }
                                            edge="end"
                                        >
                                            +
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                        <TextField
                            label="Preço maior que"
                            onChange={(e) => changeFilter("price_up", e.target.value)}
                            value={params.price_up}
                            inputProps={{
                                sx: { textAlign: "center" },
                            }}
                        />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                        <TextField
                            label="Preço menor que"
                            onChange={(e) => changeFilter("price_down", e.target.value)}
                            value={params.price_down}
                            inputProps={{
                                sx: { textAlign: "center" },
                            }}
                        />
                    </FormControl>

                    <Button
                        sx={{
                            m: 1,
                            color: "#fff",
                            fontWeight: 700,
                            textTransform: "capitalize",
                        }}
                        onClick={() => {
                            setIsFilter(false)
                        }}
                        color="tertiary"
                        variant="contained"
                    >
                        Filtrar Listagem
                    </Button>
                </CardContent>
            </Card>
        </Modal>
    );
};

export default ModalFilter;
