import React from "react";
import { Box, Button } from "@material-ui/core";
import { useBudgetFormContext } from "../../../context/BudgetFormContext";
import STEP from "../../../constants/STEP";

const FirstActions = () => {
  const { handleCancel, handleNextStep, budgets } = useBudgetFormContext();
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
      <Button
        sx={{ marginRight: 2, textTransform: "capitalize" }}
        color="fourth"
        variant="contained"
        onClick={handleCancel}
      >
        Cancelar
      </Button>
      <Button
        sx={{ textTransform: "capitalize", color: "#fff" }}
        color="tertiary"
        variant="contained"
        onClick={handleNextStep}
        disabled={budgets.length === 0}
      >
        Próxima Etapa
      </Button>
    </Box>
  );
};

const SecondActions = () => {
  const { handleBackStep, handleSubmit, budgets } = useBudgetFormContext();

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
      <Button
        sx={{ marginRight: 2, textTransform: "capitalize" }}
        color="fourth"
        variant="contained"
        onClick={handleBackStep}
      >
        Voltar
      </Button>
      <Button
        sx={{ textTransform: "capitalize", color: "#fff" }}
        color="tertiary"
        variant="contained"
        onClick={handleSubmit}
        disabled={budgets.length === 0}
      >
        Finalizar
      </Button>
    </Box>
  );
};

const FormActions = () => {
  const { step } = useBudgetFormContext();

  return (
    <>
      {step === STEP.BUDGET && <FirstActions />}
      {step === STEP.EMAIL && <SecondActions />}
    </>
  );
};

export default FormActions;
