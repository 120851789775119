import { addDays, format } from "date-fns";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import STEP from "../constants/STEP";
import useFetchTaxes from "../hooks/useFetchTaxes";
import useMail from "../hooks/useMail";
import { createBudget } from "../services/budgetService";
import { sendEmail } from "../services/emailService";
import { getUserName, getUserId } from "../services/auth";
import { createDatesArr } from "../utils/utils";
import { dateToString, stringToDate } from "../mapping/taxMapping";
const _ = require("lodash");
//const defaultTax = 7.25 / 100;
const defaultDiscount = 5;

const rulesCase = {
    verify: (dates, rule) => {
      const ruleDates = Object.keys(rule).slice(1);
      if (dates.length < rule[ruleDates[0]]?.number_min_nights) return false;
      return ruleDates.filter(ruleDate =>
          dates.includes(ruleDate)
      )
    },
    apply: (dates, rule, prices) => {
      const getDate = (rule) =>
          rule[dates[0]]?.flag_nights_free === "PRI" ? dates[0] : dates[dates.length - 1];

      return rule[dates[0]]?.flag_nights_free === 'PRI' ||  rule[dates[0]]?.flag_nights_free === 'ULT'?
          {
            value:
              prices[getDate(rule)]?.daily_price * 1.0725 //1.0725 = hotel tax

          } : {
            percentual:
              _.sum(dates.map((date) => {
                if(rule[date]?.perc_discount) {
                  return (prices[date]?.daily_price * 1.0725) * (rule[date]?.perc_discount / 100) //1.0725 = hotel tax
                } else {
                  return 0
                }              
              })),
          }
    },

  "5x4 1A NOITE FREE": {
    verify: (dates, rule) => {
      const beforeDayBeginPeriod = dateToString(
        addDays(stringToDate(dates[0]), -1)
      );
      const afterDayEndPeriod = dateToString(
        addDays(stringToDate(dates[dates.length - 1]), 1)
      );
      if (rule[beforeDayBeginPeriod] || rule[afterDayEndPeriod]) return false;

      return dates.every((date) => rule[date]?.number_nights_free);
    },
    apply: (dates, rule, prices) => {
      const getDate = (rule) =>
        rule.flag_nights_free === "PRI" ? dates[0] : dates[dates.length - 1];

      const daily_discount = prices[getDate(rule)]?.daily_price || 0;

      return {
        discount:
          (100 * daily_discount) /
          _.sum(dates.map((date) => prices[date]?.daily_price || 0)),
      };
    },
  },
  "4RN -5%": {
    verify: (dates, rule) => {
      const ruleDates = Object.keys(rule).slice(1);
      if (dates.length < rule[ruleDates[0]]?.number_min_nights) return false;
      return ruleDates.filter(ruleDate =>
          dates.includes(ruleDate)
      )
    },
    apply: (dates, rule) => ({
      discount:
        _.sum(dates.map((date) => rule[date]?.perc_discount || 0)) /
        dates.length,
    }),
  },
  "4RN -10%": {
    verify: (dates, rule) => {
      const ruleDates = Object.keys(rule).slice(1);
      if (dates.length < rule[ruleDates[0]]?.number_min_nights) return false;
      return ruleDates.filter(ruleDate =>
          dates.includes(ruleDate)
      )
    },
    apply: (dates, rule) => ({
      discount:
        _.sum(dates.map((date) => rule[date]?.perc_discount || 0)) /
        dates.length,
    }),
  },
  "5RN -10%": {
    verify: (dates, rule) => {
      const ruleDates = Object.keys(rule).slice(1);
      if (dates.length < rule[ruleDates[0]]?.number_min_nights) return false;
      return ruleDates.filter(ruleDate =>
          dates.includes(ruleDate)
      )
    },
    apply: (dates, rule) => ({
      discount:
        _.sum(dates.map((date) => rule[date]?.perc_discount || 0)) /
        dates.length,
    }),
  },
  "6RN -10%": {
    verify: (dates, rule) => {
      const ruleDates = Object.keys(rule).slice(1);
      if (dates.length < rule[ruleDates[0]]?.number_min_nights) return false;
      return ruleDates.filter(ruleDate =>
          dates.includes(ruleDate)
      )
    },
    apply: (dates, rule) => ({
      discount:
        _.sum(dates.map((date) => rule[date]?.perc_discount || 0)) /
        dates.length,
    }),
  },
  "7RN -10%": {
    verify: (dates, rule) => {
      const ruleDates = Object.keys(rule).slice(1);
      if (dates.length < rule[ruleDates[0]]?.number_min_nights) return false;
      return ruleDates.filter(ruleDate =>
          dates.includes(ruleDate)
      )
    },
    apply: (dates, rule) => ({
      discount:
        _.sum(dates.map((date) => rule[date]?.perc_discount || 0)) /
        dates.length,
    }),
  },
};
//Valores iniciais do contexto global
export const BudgetFormContextDefaultValues = {
  params: {
    check_in: new Date(),
    check_out: new Date(),
    adults_quantity: 2,
    children_quantity: 1,
    babies_quantity: 0,
  },
  data: { dates: [], tax: [], prices: [], rules: [] },
  checkedRules: {},
  selectedDates: [],
  fetchData: () => {},
  setRange: () => {},
  setSelectedDates: () => {},
  setCheckedRules: () => {},
};

//Instância do contexto, recebendo os valores iniciais
export const BudgetFormContext = createContext(BudgetFormContextDefaultValues);

//Provedor que é resposável pela manipulação e controle do estado
const BudgetFormContextProvider = ({ children, setIsForm }) => {
  const [selectedPrices, setSelectedPrices] = useState([]);
  const [extras, setExtras] = useState(0);
  const [loading, setLoading] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [budgets, setBudgets] = useState([]);
  const [params, changeParams] = useState(
    BudgetFormContextDefaultValues.params
  );
  const [checkedRules, setCheckedRules] = useState({});
  const [selectedDates, setSelectedDates] = useState([]);

  const [step, setStep] = useState(STEP.BUDGET);

  const { data, fetchData } = useFetchTaxes();
  const { mail, changeMail } = useMail();
  const [discountInput, setDiscountInput] = useState(0);
  const [percDiscount, setPercDiscount] = useState(0);


  const stop = 1,
        fecho = 2,
        fecho_in = 3,
        fecho_out = 4;
  // useEffect(() => {
  //   setSelectedDates(_.take(_.drop(data.dates, 15), 15));
  // }, [_, data.dates]);

  useEffect(() => {
    if (selectedDates.length === 0) return;

    const ruledDiscount = percDiscount > 0 ? percDiscount / 100 : defaultDiscount / 100

    const groupedPrices = [];
    data.prices.forEach((item) => {
      if (
          selectedDates.some((date) => {
            const dateParts = selectedDates[selectedDates.length - 1].split("/");
            const endDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
        
            return (
            item[date]?.restriction_type === stop) ||
          item[selectedDates[0]]?.restriction_type === fecho_in ||
          item[selectedDates[0]]?.restriction_type === fecho ||
          item[format(addDays(endDate, 1), 'dd/MM/yyyy')]?.restriction_type === fecho_out ||
          item[format(addDays(endDate, 1), 'dd/MM/yyyy')]?.restriction_type === fecho
            })) return;

      const currentPrice = {};
      selectedDates.forEach((date) => {
        const dailyPrice = item[date]?.daily_price || 0;
        const totalPrice =
          dailyPrice +
          (currentPrice[item.acommodation_description]
            ?.total_daily_without_tax || 0);

        const priceDate = item[date]?.accomodation_date;
        //const hotelTax = totalPrice * defaultTax;

        //const discount = (totalPrice + hotelTax) * ruledDiscount;
        const children_free = item[date]?.children_free
        const min_room_night_children_free = item[date]?.min_room_night_children_free

        currentPrice[item.acommodation_description] = {
          total_daily_without_tax: totalPrice,
          total_cost_accomodation: totalPrice,
          total_daily_with_tax: totalPrice * 1.0725,  //1.0725 = hotel tax
          total_daily_with_tax_discount: totalPrice * 1.0725, //1.0725 = hotel tax
          acommodation_date: priceDate,
          children_free,
          min_room_night_children_free,
          tax_id: item[date]?.tax_id,
        };
      });
      groupedPrices.push({
        ...currentPrice[item.acommodation_description],
        acommodation_description: item.acommodation_description,
      });
    });

    for (let i = 0; i < groupedPrices.length; i++) {
      const item = groupedPrices[i];
      const rules = data.rules
        .filter(
          (rule) =>
            checkedRules[rule.rule_description] &&
            //rulesCase[rule.rule_description].verify(selectedDates, rule)
            rulesCase.verify(selectedDates, rule)
        )
        .map((rule) =>
          //rulesCase[rule.rule_description].apply(
          rulesCase.apply(
            selectedDates,
            rule,
            data.prices.find(
              (price) =>
                price.acommodation_description === item.acommodation_description
            )
          )
        );

      const rulePercDiscount = _.sumBy(rules, "percentual") || 0;
      const ruleValueDiscount = _.sumBy(rules, "value") || 0;

      const price =
        item.total_daily_with_tax -
        (rulePercDiscount > 0
          ? rulePercDiscount
          : ruleValueDiscount);

      const priceWithDiscount =
        item.total_daily_with_tax_discount -
        (rulePercDiscount > 0
          ? rulePercDiscount
          : ruleValueDiscount);

      const discountValue = priceWithDiscount * ruledDiscount;
      item.total_daily_with_tax_discount = (priceWithDiscount + extras) - (discountInput > 0 ? discountInput : discountValue)
      item.total_cost_accomodation = price + extras
    }

    setSelectedPrices(groupedPrices);
  }, [params, data, checkedRules, selectedDates, extras, discount, discountInput, percDiscount]);

  const handleCancel = useCallback(() => setIsForm(false), [setIsForm]);
  const handleSubmit = useCallback(async () => {
    const details = budgets.map((budget) => ({
      check_in: budget.check_in,
      check_out: budget.check_out,
      adults_quantity: budget.adults_quantity,
      children_quantity: budget.children_quantity,
      babies_quantity: budget.babies_quantity,
      prices: budget.prices.map((price) => ({
        uh_type: price.acommodation_description,
        date: price.acommodation_date,
        id_tarifa: price.tax_id || 0,
        price: price.total_cost_accomodation,
        discount: 0,
      })),
    }));

    const budgetObject = {
      mail: mail.body,
      clerk: getUserName(),
      client: {
        name: mail.clientName,
        email: mail.clientEmail,
        phone: mail.clientPhone,
      },
      details,
    };

    try {

      if (!mail.clientNameError && !mail.clientEmailError) {
        const response = await createBudget(budgetObject);
        if (response.success) {
          await sendEmail({
            operatorId: getUserId(),
            to: mail.clientEmail,
            cc: mail.copyEmail,
            subject: "Segue seu orçamento",
            mailBody: mail.body,
            attachments: mail.attachments,
          });
          window.location.href = "/app/orcamentos";
        } else {
          alert('Erro ao criar o Orçamento tente novamente por favor')
        }



      } else {
        alert('Erros no formulário preencha corretamente por favor');
      }

    } catch (e) {}
  }, [mail, budgets]);

  const addToBudget = useCallback(() => {
    const newBudget = {
      ...params,
      check_in: stringToDate(selectedDates[0]),
      check_out: addDays(stringToDate(selectedDates[selectedDates.length - 1]), 1),
      prices: selectedPrices,
      children_free: Math.max.apply(
        Math,
        selectedPrices.map((price) => price.children_free)
      ),
      min_room_night_children_free: selectedDates.length >= Math.max.apply(
        Math,
        selectedPrices.map((price) => price.min_room_night_children_free)
      ) ? true : false,
      extras: extras || 0,
    };

    setBudgets([...budgets, newBudget]);
    setSelectedPrices([]);
  }, [params, budgets, selectedPrices]);

  const removeFromBudget = useCallback(
    (indexOf) => {
      setBudgets(budgets.filter((_, i) => i !== indexOf));
    },
    [budgets]
  );

  const handleSearch = useCallback(async () => {
    const check_in = format(params.check_in, "dd/MM/yyyy");
    const check_out = format(params.check_out, "dd/MM/yyyy");
    const check_in15 = format(addDays(params.check_in, -15), "dd/MM/yyyy");
    const check_out15 = format(addDays(params.check_out, 15), "dd/MM/yyyy");
    setSelectedDates(createDatesArr(params.check_in, params.check_out));
    setLoading(true);
    await fetchData({
      ...params,
      check_in,
      check_out,
      check_in15,
      check_out15,
    });

    setLoading(false);

    // setRange([data.dates.indexOf(check_in), data.dates.indexOf(check_out)]);
  }, [data.dates, params, fetchData]);

  const handleNextStep = useCallback(() => {
    const newStep = Object.values(STEP)[step + 1];
    setStep(newStep);
  }, [step]);

  const handleBackStep = useCallback(() => {
    const newStep = Object.values(STEP)[step - 1];
    setStep(newStep);
  }, [step]);

  const value = useMemo(
    () => ({
      data,
      step,
      loading,
      selectedPrices,
      mail,
      budgets,
      params,
      selectedDates,
      checkedRules,
      changeParams,
      changeMail,
      handleCancel,
      handleSubmit,
      handleSearch,
      handleNextStep,
      handleBackStep,
      addToBudget,
      removeFromBudget,
      setExtras,
      setDiscount,
      setSelectedDates,
      setCheckedRules,
      defaultDiscount,
      extras,
      setPercDiscount,
      setDiscountInput
    }),
    [
      data,
      step,
      loading,
      selectedPrices,
      selectedDates,
      checkedRules,
      mail,
      budgets,
      params,
      changeMail,
      handleCancel,
      handleSubmit,
      handleSearch,
      handleNextStep,
      handleBackStep,
      removeFromBudget,
      addToBudget,
      defaultDiscount,
      extras,
    ]
  );

  return (
    <BudgetFormContext.Provider value={value}>
      {children}
    </BudgetFormContext.Provider>
  );
};

//Hook que será utilizando nos componentes para acesso ao contexto
export const useBudgetFormContext = () => useContext(BudgetFormContext);

export default BudgetFormContextProvider;
