// import { v4 as uuid } from "uuid";
const budgets = [
  {
    client: "Cleyton Davi Hort",
    dateInOut: "06/08/21 - 08/08/21",
    adults: 2,
    criCHD1: 2,
    criCHD2: 1,
    special: "Dia dos Pais",
    operator: "João da Silva",
    status: "Enviado",
  },
  {
    client: "Thiago Alexandre Klein",
    dateInOut: "06/08/21 - 08/08/21",
    adults: 2,
    criCHD1: 1,
    criCHD2: 0,
    special: "Dia dos Pais",
    operator: "João da Silva",
    status: "Enviado",
  },
  {
    client: "Maykon Ricardo Meier",
    dateInOut: "06/08/21 - 08/08/21",
    adults: 2,
    criCHD1: 1,
    criCHD2: 1,
    special: "BAR23",
    operator: "Pedro Souza",
    status: "Enviado",
  },
  {
    client: "Alisson Fabiano",
    dateInOut: "02/08/21",
    adults: 2,
    criCHD1: 0,
    criCHD2: 0,
    special: "",
    operator: "Maria H.",
    status: "Pendente",
  },
  {
    client: "David Souza",
    dateInOut: "02/08/21",
    adults: 2,
    criCHD1: 2,
    criCHD2: 0,
    special: "",
    operator: "Joana Pops",
    status: "Pendente",
  },
];

export default budgets;
