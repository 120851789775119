import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
    AppBar,
    Toolbar,
    Box,
    Typography,
    Hidden,
    IconButton,
} from "@material-ui/core";
import {
    HomeOutlined as HomeIcon,
    PeopleOutline as PeopleIcon,
    PlaylistAddCheckOutlined as PlaylistAddCheckIcon,
} from "@material-ui/icons";
import Logo from "../../assets/img/Logo";
import { getUserName, logout } from "../../services/auth";
import {Logout as LogoutIcon} from "@material-ui/icons";
import React from "react";

const getStyle = (type) => {
  const path = window.location.pathname;

  const styles = {
    "/app/inicio": { color: "#FBAF17" },
    "/app/clientes": { color: "#FBAF17" },
    "/app/orcamentos": { color: "#FBAF17" },
    "/": { color: "#fff" },
  };

  const style = styles[path];
  return type === path ? style : styles["/"];
};

const DashboardNavbar = ({ to, onMobileNavOpen, ...rest }) => {
  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <RouterLink to="/app/inicio" style={getStyle("/app/inicio")}>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                  p: 2,
                }}
              >
                <HomeIcon />
                <Typography sx={{ ml: 1 }}>Início</Typography>
              </Box>
            </RouterLink>
            <RouterLink to="/app/clientes" style={getStyle("/app/clientes")}>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                  p: 2,
                }}
              >
                <PeopleIcon />
                <Typography sx={{ ml: 1 }}>Clientes</Typography>
              </Box>
            </RouterLink>
            <RouterLink
              to="/app/orcamentos"
              style={getStyle("/app/orcamentos")}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                  p: 2,
                }}
              >
                <PlaylistAddCheckIcon />
                <Typography sx={{ ml: 1 }}>Orçamentos</Typography>
              </Box>
            </RouterLink>
          </Box>
        </Box>
        <Hidden lgDown>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                p: 2,
              }}
            >
              <Typography color="textTertiary" variant="h5">
                {getUserName()}
              </Typography>
              <Typography color="textTertiary" variant="body2">
                {"Configurações"}
              </Typography>
            </Box>

              <IconButton
                  onClick={(_) => {
                      logout();
                      window.location.href = '/login';
                  }}
                  aria-label="LogoutIcon"
              >
                  <LogoutIcon sx={{color: '#ffffff'}} fontSize="medium" />
              </IconButton>
          </Box>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default DashboardNavbar;
