import React, { useEffect, useRef, useState } from "react";
import {
  CardContent,
  TextField,
  Grid,
  Card,
  Input,
  InputLabel, Button,
} from "@material-ui/core";
import { Editor as EditorTiny } from "@tinymce/tinymce-react";
import { generateEmailTemplate } from "../../../utils/genetateEmailTemplateDinamic";
import { useBudgetFormContext } from "../../../context/BudgetFormContext";
import { getEmail } from '../../../services/emailService'

const FormEmail = () => {
  const { mail, changeMail, budgets } = useBudgetFormContext();
  const [emailTemplate, setEmailTemplate] = useState();
  const editorRef = useRef(null);

  const validEmail = (email) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  useEffect(() => {
    const fetch = async () => {
      const email = await getEmail({fileName: 'email-template.html'})
      setEmailTemplate(email)  
      changeMail("body", generateEmailTemplate(mail.clientName, budgets, email))
    
    }
  fetch();   
  },[])

  useEffect(() => {
    changeMail("body", generateEmailTemplate(mail.clientName, budgets, emailTemplate));
  }, [mail.clientName, changeMail, budgets]);

  return (
    <Card xs={12} sx={{ height: "90vh", overflow: "scroll" }}>
      <CardContent>
        <div>
          <h4 style={{ color: "#034EA2", fontSize: "16px" }}>
            Dados do Cliente
          </h4>
          <span style={{ color: "#868E96", fontSize: "14px" }}>
            Informações muito importantes, preencha com atenção
          </span>
        </div>

        <Grid container={true} spacing={3} sx={{ marginTop: "8px" }}>
          <Grid item xs={12}>
            <TextField
              required
              autoComplete="new-password"
              error={mail.clientNameError}
              helperText={'Nome do Cliente é obrigatório'}
              id="clientName"
              name="clientName"
              label="Nome do Cliente"
              fullWidth
              value={mail.clientName}
              onChange={(e) => {
                !mail.clientName ? changeMail("clientNameError", true) : changeMail("clientNameError", false)
                changeMail("clientName", e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              autoComplete="new-password"
              error={mail.clientEmailError}
              helperText={'Insira um E-mail válido'}
              id="email"
              name="email"
              label="Email do Cliente"
              fullWidth
              value={mail.clientEmail}
              onChange={(e) => {
                if (!mail.clientEmail || !validEmail(e.target.value))  changeMail("clientEmailError", true)
                else changeMail("clientEmailError", false)
                changeMail("clientEmail", e.target.value)

              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              autoComplete="new-password"
              id="phone"
              name="phone"
              label="Telefone"
              fullWidth
              value={mail.clientPhone}
              onChange={(e) => changeMail("clientPhone", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="email"
              name="email"
              label="E-mail em cópia"
              fullWidth
              value={mail.copyEmail}
              onChange={(e) => changeMail("copyEmail", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="files">Escolher Anexos</InputLabel>
            <Input
              type="file"
              inputProps={{ multiple: true }}
              //multiple
              id="file"
              name="file"
              label="E-mail em cópia"
              onChange={(e) => {
                if (e.target.files.length > 4) {
                  alert("Você pode anexar apenas 4 arquivos");
                  e.target.value = "";
                } else {
                  changeMail("attachments", e.target.files);
                }
              }}
            />
          </Grid>
        </Grid>

        <div>
          <h4 style={{ color: "#034EA2", fontSize: "16px", marginTop: "24px" }}>
            Conteúdo do E-mail
          </h4>
          <span style={{ color: "#868E96", fontSize: "14px" }}>
            Revise as informações antes de enviar ao cliente
          </span>
        </div>
        <br />
        {/*<ReactQuill value={mail.body}*/}
        {/*            onChange={() => {}} />*/}

        <EditorTiny
          initialValue={mail.body}
          apiKey='lk7fq643xfhmzfl6rg6yzb78cqmsrqbk31b8rihiefcvukuy'
          onInit={(e, editor) => editorRef.current = editor}
          init={{
            height: 700,
            menubar: false,
            statusbar: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar:
              "undo redo | formatselect | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
          // onEditorChange={(newValue, editor) =>
          //   changeMail("body", editorRef.current.getContent())
          // }
         />
        <Button
            sx={{ textTransform: "capitalize", color: "#fff", mt: 1, float: 'right' }}
            color="tertiary"
            variant="contained"
            onClick={() =>
              changeMail("body", editorRef.current.getContent())}
        >
          Salvar mudanças no e-mail
        </Button>
      </CardContent>
    </Card>
  );
};

export default FormEmail;
