import React from "react";
import { Box, Card, CardContent, Divider, Grid, Step } from "@material-ui/core";
import FormActions from "./FormActions";
import FormHeader from "./FormHeader";
import FormList from "./FormList";
import FormSelection from "./FormSelection";
import { useBudgetFormContext } from "../../../context/BudgetFormContext";
import Steps from "../../steps/Steps";
import FormEmail from "./FormEmail";
import ActualSelectionForm from "./ActualSelectionForm";

const BudgetGrid = () => {
  const { data } = useBudgetFormContext();
  return (
    <Card>
      <CardContent container={true} spacing={3}>
        <FormHeader />
      </CardContent>
      {data.dates.length > 0 && (
        <>
          <Divider />
          <CardContent container={true} spacing={3}>
            <FormList />
          </CardContent>
          <Divider />
          <CardContent container={true} spacing={3}>
            <FormSelection />
          </CardContent>
        </>
      )}
    </Card>
  );
};
const BudgetForm = () => {
  return (
    <>
      <Grid container={true} spacing={2}>
        <Grid item md={9.5} xs={12}>
          <Box sx={{ height: "80vh" }} overflow={"auto"}>
            <Steps>
              <Step>
                <BudgetGrid />
              </Step>
              <Step>
                <FormEmail />
              </Step>
            </Steps>
          </Box>
        </Grid>
        <Grid item md={2.5} xs={12}>
          <ActualSelectionForm />
          <FormActions />
        </Grid>
      </Grid>
    </>
  );
};

export default BudgetForm;
