const STORAGE_KEY = "costao";

const isLogged = () => !!localStorage.getItem(STORAGE_KEY);
const login = (token) => localStorage.setItem(STORAGE_KEY, token);
const logout = () => localStorage.removeItem(STORAGE_KEY);
const getToken = () => localStorage.getItem(STORAGE_KEY);

const getUserName = () => {
    if (!getToken()) return;
    const jwt = getToken().split('.')[1]
    const clerk = window.atob(jwt);
    return JSON.parse(clerk).id.user_name;
}

const getUserId = () => {
    if (!getToken()) return;
    const jwt = getToken().split('.')[1]
    const clerk = window.atob(jwt);
    return JSON.parse(clerk).id.user_id;
}

const getExpirationDate = (jwtToken) => {
    if (!jwtToken) {
        return null;
    }
    const jwt =  JSON.parse(window.atob(jwtToken.split('.')[1]))
    // multiply by 1000 to convert seconds into milliseconds
    return jwt && jwt.exp ? jwt.exp * 1000 : null;
};

const isExpired = (exp) => {
    if (!exp) {
        return false;
    }

    return Date.now() > exp;
};

export { isLogged, login, logout, getToken, getExpirationDate, isExpired, getUserName, getUserId };
