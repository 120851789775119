import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Container, TextField, Typography } from '@material-ui/core';
import { login } from '../services/auth';
import api from '../services/api';

const Login = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Login | Costão do Santinho</title>
      </Helmet>
      <Box
        sx={{ backgroundColor: 'background.default', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
        <Container maxWidth="sm">
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Email inválido').max(255).required('O email é obrigatório'),
              password: Yup.string().max(255).required('A senha é obrigatório')
            })} onSubmit={ async (values, errors) => {
                const authObj = await api.post('/auth', values);
                if (authObj.data.token) {
                    login(authObj.data.token);
                    navigate('/app/orcamentos', { replace: true });
                } else {
                    errors.setFieldError('email', 'E-mail ou senha inválidos');
                    errors.setFieldError('password', 'E-mail ou senha inválidos');
                }
            }} >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2" >
                    Login
                  </Typography>
                  <Typography color="textSecondary" gutterBottom variant="body2" >
                    Faça o login para acessar o portal
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined" />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Senha"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined" />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained" >
                    Entrar
                  </Button>
                </Box>
                {/*<Typography*/}
                {/*  color="textSecondary"*/}
                {/*  variant="body1" >*/}
                {/*  Não tem uma conta?*/}
                {/*  {' '}*/}
                {/*  <Link*/}
                {/*    component={RouterLink}*/}
                {/*    to="/register"*/}
                {/*    variant="h6" >*/}
                {/*    Criar*/}
                {/*  </Link>*/}
                {/*</Typography>*/}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
