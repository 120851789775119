import { addDays, format } from 'date-fns';

export const createDatesArr = (check_in, check_out) => {
    let dateArray = [];
    let currentDate = new Date(check_in);
    const endDate = new Date(check_out - 1);

    while (currentDate <= endDate) {
        dateArray.push(format(currentDate, 'dd/MM/yyyy'));
        currentDate = addDays(currentDate, 1);
    }
    return dateArray;
}