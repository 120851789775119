import { useCallback, useMemo, useState } from "react";

const useFilter = () => {
    const [clerk, setCLerk] = useState('');
    const [client, setClient] = useState('');
    const [check_in, setCheckIn] = useState('');
    const [check_out, setCheckOut] = useState('');
    const [adults_quantity, setAdultsQuantity] = useState(0);
    const [children_quantity, setChildrenQuantity] = useState(0);
    const [babies_quantity, setBabiesQuantity] = useState(0);
    const [price_up, setPriceUp] = useState(null);
    const [price_down, setPriceDown] = useState(null);

    const changeFilter = useCallback(async(name, value) => {
        switch (name) {
            case "clerk":
                return setCLerk(value);
            case "client":
                return setClient(value);
            case "check_in":
                return setCheckIn(value);
            case "check_out":
                return setCheckOut(value);
            case "adults_quantity":
                return setAdultsQuantity(value);
            case "children_quantity":
                return setChildrenQuantity(value);
            case "babies_quantity":
                return setBabiesQuantity(value);
            case "price_up":
                return setPriceUp(value);
            case "price_down":
                return setPriceDown(value);
            default:
                return null;
        }
    }, []);

    return useMemo(
        () => ({
            filter: {
                clerk,
                client,
                check_in,
                check_out,
                adults_quantity,
                children_quantity,
                babies_quantity,
                price_up,
                price_down,
            },
            changeFilter,
        }),
        [
            clerk,
            client,
            check_in,
            check_out,
            adults_quantity,
            children_quantity,
            babies_quantity,
            price_up,
            price_down,
            changeFilter
        ]
    );
};

export default useFilter;
