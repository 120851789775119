import { format, getDay, parse } from "date-fns";
import { ptBR } from "date-fns/locale";

const dayOfWeek = {
  0: "Dom",
  1: "Seg",
  2: "Ter",
  3: "Qua",
  4: "Qui",
  5: "Sex",
  6: "Sáb",
};

export const getDayOfWeek = (date) => dayOfWeek[getDay(date)];

export const stringToDate = (dateString) =>
  parse(dateString, "dd/MM/yyyy", new Date());

export const dateToString = (date) =>
  format(date, "dd/MM/yyyy", { locale: ptBR });

const getColumns = (tax = {}) => {
  const columns = [];
  for (const tariff of tax) {
    const date = parse(tariff.accomodation_date, "dd/MM/yyyy", new Date());
    columns.push({
      dayOfWeek: getDayOfWeek(date),
      dayOfMonth: format(date, "dd/MM", { locale: ptBR }),
      dateSort: format(date, "yyyy/MM/dd", { locale: ptBR }),
    });
  }

  columns.sort((a, b) =>
    a.dateSort.localeCompare(b.dateSort, undefined, {
      numeric: true,
      sensitivity: "base",
    })
  );

  return columns;
};

const mappingTariffsRows = (tax = {}) => {
  const rooms123 = {};
  const superiors = {};
  const suites = {};

  for (const tariff of tax) {
    rooms123[tariff.accomodation_date] = tariff.room123;
    superiors[tariff.accomodation_date] = tariff.superior;
    suites[tariff.accomodation_date] = tariff.suites;
  }

  return { rooms123, superiors, suites };
};

const mappingValuesRows = (values = {}) => {
  const prices = {};

  for (const value of values) {
    if (!prices[value.uh_type_description])
      prices[value.uh_type_description] = {};

    prices[value.uh_type_description][value.accomodation_date] =
      value.daily_price;
  }

  return prices;
};

const tax = {
  getColumns,
  mappingTariffsRows,
  mappingValuesRows,
};

export default tax;
