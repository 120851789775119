import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Modal,
} from "@material-ui/core";
import {
  Delete as DeleteIcon,
  PersonOutline as PersonIcon,
  Email as EmailIcon,
} from "@material-ui/icons";
import { deleteBudget, getAllBudgets } from "../../services/budgetService";
import {getEmail} from "../../services/emailService";
import ModalFilter from "./ModalFilter";

const styleByStatus = {
  Enviado: { color: "#37B24D" },
};

const BudgetList = ({
  // budgets,
  selectedCustomerIds,
  setSelectedCustomerIds,
  isFilter,
  setIsFilter,
  setQuantityFiltered,
  changeFilter,
  filter: params,
  ...rest
}) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const [budgets, setBudgets] = useState([]);
  const [totalBudgets, setTotalBudgets] = useState(0);

  const [modalOpen, setModalOpen] = useState(false);
  const [emailData, setEmailData] = useState('');

  const format = (price) =>
  new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(price);

  useEffect(() => {
    getAllBudgets({page, limit, filter: params}).then(({data, totalBudgets: count }) => {
      setBudgets(data);
      setTotalBudgets(count)
    });

    setQuantityFiltered(Object.values(params).filter(x => x !== '' && x !== 0 && x !== null).length)
  }, [page, limit, isFilter, params]);

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = budgets.map((product) => product.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    console.log(newPage)
    setPage(newPage);
  };

  //const handleEdit = () => {};

  const handleDelete = async (id) => {
    await deleteBudget(id);
    window.location.href = "/app/orcamentos";
  };

  const handleOpenModal = async (email) => {
    const data = await getEmail({fileName: email});
    data && setEmailData(data);
    setModalOpen(!modalOpen);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    height: '95%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
  };


  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === budgets.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0 &&
                      selectedCustomerIds.length < budgets.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>Cliente</TableCell>
                <TableCell>Check_in</TableCell>
                <TableCell>Check_out</TableCell>
                <TableCell>Adultos</TableCell>
                <TableCell>Cri. CHD1</TableCell>
                <TableCell>Cri. CHD2</TableCell>
                <TableCell>Preço</TableCell>
                <TableCell>Especial</TableCell>
                <TableCell>Operador</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Data de Envio</TableCell>
                {/*<TableCell>Ações</TableCell>*/}
                <TableCell>Ver E-mail</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {budgets.slice(0, limit).map((budget, i) => (
                <TableRow
                  hover
                  key={i}
                  selected={selectedCustomerIds.indexOf(budget.id) !== -1}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(budget.id) !== -1}
                      onChange={(event) => handleSelectOne(event, budget.id)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>{budget.client}</TableCell>
                  <TableCell>{budget.check_in}</TableCell>
                  <TableCell>{budget.check_out}</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <PersonIcon sx={{ pr: 0.5 }} fontSize="small" />
                      {budget.adults_quantity}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <PersonIcon sx={{ pr: 0.5 }} fontSize="small" />
                      {budget.children_quantity}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <PersonIcon sx={{ pr: 0.5 }} fontSize="small" />
                      {budget.babies_quantity}
                    </Box>
                  </TableCell>
                  <TableCell>{format(budget.price) || "-"}</TableCell>
                  <TableCell>{budget.special || "-"}</TableCell>
                  <TableCell>{budget.clerk}</TableCell>
                  <TableCell sx={styleByStatus[budget.status]}>
                    {budget.status}
                  </TableCell>
                  <TableCell>
                    {budget.created_at}
                  </TableCell>
                  {/*<TableCell>*/}
                  {/*  /!* <IconButton onClick={handleEdit} aria-label="EditIcon">*/}
                  {/*    <EditIcon fontSize="small" />*/}
                  {/*  </IconButton> *!/*/}
                  {/*  <IconButton*/}
                  {/*    onClick={(_) => handleDelete(budget.id)}*/}
                  {/*    aria-label="DeleteIcon"*/}
                  {/*  >*/}
                  {/*    <DeleteIcon fontSize="small" />*/}
                  {/*  </IconButton>*/}
                  {/*</TableCell>*/}
                  <TableCell>
                    <IconButton
                        onClick={(_) => handleOpenModal(budget.emailFileName)}
                        aria-label="EmailIcon"
                    >
                      <EmailIcon fontSize="medium" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalBudgets}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        labelRowsPerPage={`${budgets.length} registros encontrados`}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
        rowsPerPageOptions={[5, 10, 25]}
      />
      <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div dangerouslySetInnerHTML={{__html: emailData}} />
        </Box>
      </Modal>
      <ModalFilter
          open={isFilter}
          onClose={() => setIsFilter(false)}
          setIsFilter={setIsFilter}
          changeFilter={changeFilter}
          params={params}
      />
    </Card>
  );
};

BudgetList.propTypes = {
  budgets: PropTypes.array.isRequired,
};

export default BudgetList;
