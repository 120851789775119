import axios from "axios";

export const deleteBudget = (id) => {
  return axios
    .delete(`${process.env.REACT_APP_BASE_URL}/budgets/${id}`)
    .then((response) => response.data)
    .catch((e) => Promise.resolve(e));
};

export const getAllBudgets = (data) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/budgets`, {params: data})
    .then((response) => response.data)
    .catch((e) => Promise.resolve(e));
};

export const createBudget = (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/budgets`, data)
    .then((response) => response.data)
    .catch((e) => Promise.resolve(e));
};
