import axios from "axios";

export const sendEmail = (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/email/with-attachments`, data)
    .then((response) => response.data)
    .catch((e) => Promise.resolve(e));
};

export const uploadFile = (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/email/upload-files`, data)
    .then((response) => response.data)
    .catch((e) => Promise.resolve(e));
};

export const getEmail = (data) => {
  return axios
      .post(`${process.env.REACT_APP_BASE_URL}/email/get-email`, data)
      .then((response) => response.data)
      .catch((e) => Promise.resolve(e));
};

export const getEmailText = () => {
  return axios
      .get(`${process.env.REACT_APP_BASE_URL}/email/get-email-text`)
      .then((response) => response.data)
      .catch((e) => Promise.resolve(e));
};

export const saveEmail = (data) => {
  return axios
      .post(`${process.env.REACT_APP_BASE_URL}/email/set-email`, data)
      .then((response) => response.data)
      .catch((e) => Promise.resolve(e));
};

