import {
  Divider,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Box,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { format } from "date-fns";
import { useBudgetFormContext } from "../../../context/BudgetFormContext";
import { Delete as DeleteIcon } from "@material-ui/icons";

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#d8d8d8",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const formatPrice = (price) =>
  new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(price);

const ActualSelectionForm = () => {
  const { budgets, removeFromBudget } = useBudgetFormContext();

  return (
    <Card sx={{ height: "80vh" }}>
      <CardHeader title={`Seleção Atual (${budgets.length}/3)`} />
      <Divider />
      <Box sx={{ height: "calc(80vh - 54px)" }} overflow={"auto"}>
        <CardContent>
          {budgets.map((budget, i) => (
            <Card key={i} variant="outlined" sx={{ mt: i > 0 ? 2 : 0 }}>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 15,
                      fontFamily: "Poppins",
                      fontWeight: 700,
                      color: "#034EA2",
                    }}
                  >
                    {format(budget.check_in, "dd/MM/yyyy")} -{" "}
                    {format(budget.check_out, "dd/MM/yyyy")}
                  </Typography>
                  <IconButton
                    aria-label="deletar"
                    sx={{ p: 0 }}
                    onClick={(_) => removeFromBudget(i)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    color: "#495057",
                  }}
                >
                  {budget.adults_quantity} Adultos -{" "}
                  {budget.children_quantity + budget.babies_quantity} Crianças
                </Typography>
                <Typography
                  sx={{
                    fontSize: 13,
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    color: "#343A40",
                  }}
                >
                  <strong>Total: </strong>Extras + Desc à Vista c/ taxas
                </Typography>
                <Table size={"small"} sx={{ mt: 2 }}>
                  <TableBody>
                    {budget.prices.map((price) => (
                      <StyledTableRow key={price.acommodation_description}>
                        <TableCell sx={{ fontSize: 9 }} align={"left"}>
                          {price.acommodation_description}
                        </TableCell>
                        <TableCell sx={{ fontSize: 11, fontWeight: 700, }} align={"right"}>
                          {formatPrice(price.total_cost_accomodation)}
                        </TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          ))}
        </CardContent>
      </Box>
    </Card>
  );
};

export default ActualSelectionForm;
